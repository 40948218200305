<template>
  <div class="">
    <div class="heading-sec">
      <div class="col-md-4 mt-6">
        <div class="poppins-medium font-30 heading-profile ml-7">Landing Page Banners</div>
      </div>
    </div>
    <div class="panel-content bg-white border">
      <div class="row">
        <div class="col-md-12 mt-4">
          <div class="font-20 widget-title float-left ml-10">
            List of Banners
            <span v-if="banners" class="tableTotal font-15 poppins"
              >(Total : {{ banners.length }})</span
            >
          </div>
          <div class="float-right d-flex mx-4">
            <div class="float-right">
              <router-link to="/createBanner" class="Create-btn btn btn-success btn-small"
                >Create</router-link
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-center" align="center">
        <div class="col-md-12">
          <div class="widget">
            <div class="table-area">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th class="col-md-1 text-center">#</th>

                      <th class="col-md-3">Mobile Image</th>

                      <th class="col-md-3">Desktop Image</th>

                      <!-- <th class="col-md-2">Link</th> -->
                      <th class="col-md-1 ml-2">Sequence</th>
                      <th colspan="2" class="mr-2">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in banners" v-bind:key="item._id">
                      <td class="text-center">
                        {{ index + 1 + (page - 1) * 20 }}
                      </td>

                      <td>
                        <div>{{ item.mediaMobile }}</div>
                      </td>

                      <td>
                        <div>{{ item.media }}</div>
                      </td>
                      <!-- <td class="link-area">
                        <div>{{ item.link }}</div>
                      </td> -->
                      <td class="pa-6">
                        <div>{{ item.sequence }}</div>
                      </td>
                      <!-- v-repeat -->
                      <td>
                        <div class="d-flex">
                          <div class="mr-8">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  min-width="40px"
                                  height="40px"
                                  color="green"
                                  :to="{
                                    name: 'editBanners',
                                    params: { id: item._id },
                                  }"
                                  v-bind="attrs"
                                  v-on="on"
                                  append
                                >
                                  <b-icon-pencil class="text-white"></b-icon-pencil>
                                </v-btn>
                              </template>
                              <span>Edit</span>
                            </v-tooltip>
                          </div>

                          <div class="mr-8">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  min-width="40px"
                                  height="40px"
                                  color="red"
                                  v-bind="attrs"
                                  v-on="on"
                                  v-b-modal.delete-modal
                                  @click="deleteConfirm(item._id)"
                                >
                                  <b-icon-trash color="white"></b-icon-trash>
                                </v-btn>
                              </template>
                              <span>Delete</span>
                            </v-tooltip>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <div></div>
                    <!-- end v-repeat -->
                  </tbody>
                </table>
              </div>
              <div
                class="table-body-contents text-center m-3 font-size-md font-weight-bold text-muted"
                v-if="banners"
              >
                No Data Found
              </div>

              <!-- Use text in props -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center mt-4" v-if="banners">
      <v-pagination
        class="mb-2 float-center red-color"
        v-model="page"
        :length="totalPages"
        :per-page="20"
        prev-icon="<"
        next-icon=">"
        @input="getAllbanners()"
      />
      <!-- <b-pagination
        class="mb-2 float-center justify-center"
        v-model="page"
        :total-rows="totalPages"
        :per-page="20"
        prev-icon="<"
        next-icon=">"
        @input="getAllbanners()"
      /> -->
    </div>
    <ConfirmDlg ref="confirm" />
  </div>
</template>

<script>
import homeService from '../../services/homeService';
import ConfirmDlg from '../../components/ConfirmDlg.vue';
export default {
  components: { ConfirmDlg },
  data() {
    return {
      searchText: '',
      banners: [
        {
          name: 'User 1',
          email: 'admin.palettier@palettier.com',
          mobile: '0987654321',
        },
        {
          name: 'User 2',
          email: 'admin.palettier@palettier.com',
          mobile: '0987654321',
        },
        {
          name: 'User 3',
          email: 'admin.palettier@palettier.com',
          mobile: '0987654321',
        },
        {
          name: 'User 4',
          email: 'admin.palettier@palettier.com',
          mobile: '0987654321',
        },
      ],
      page: 1,
      totalCount: 0,
      perPage: 10,
      errors: [],
      totalPages: 1,
      dialog: false,
      items: ['Cart', 'Orders', 'sizes', 'Addresses', 'Wishlist', 'Saved Looks', 'Stylist Assists'],
    };
  },
  created() {
    this.getAllbanners();
  },
  methods: {
    async getAllbanners() {
      const data = {};
      data.page = this.page;
      data.user = JSON.parse(localStorage.getItem('selectedVendor'));
      const result = await homeService.getAllBanners(data);
      if (result.status == 200) {
        this.banners = result.data.data;
        this.totalPages = result.data.maxPage;
      }
      console.log('result', this.banners);
    },
    async deleteBannerById(id) {
      const result = await homeService.deleteBanner(id);

      if (result.status === 200) {
        this.$store.commit('setSnackbar', {
          content: 'Submitted Successfully',
          icon: 'mdi-check-circle',
          color: 'success ',
          isVisible: true,
        });
        this.$router.go();
      } else {
        this.$store.commit('setSnackbar', {
          content: 'Something went wrong !',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });
      }
      console.log('result', this.banners);
    },
    async deleteConfirm(id) {
      if (await this.$refs.confirm.open('Confirm', 'Are you sure you want to delete?')) {
        this.deleteBannerById(id);
        console.log('got confirmation');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.select {
  width: 140px;
  max-height: 60px;
  font-size: 11px;
}
.link-area {
  max-width: 450px !important;
  overflow: hidden;
  white-space: wrap;
}
</style>
